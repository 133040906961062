/* eslint-disable react/jsx-no-bind */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import AccountActivationForm from "components/AccountActivationForm/AccountActivationForm";

class AccountActivation extends React.Component {
  render() {
    return (
      <GridContainer spacing={8} justify="center" alignItems="center">
        <GridItem
          md={6}
          xs={10}
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AccountActivationForm />
        </GridItem>
      </GridContainer>
    );
  }
}

export default AccountActivation;
