import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { logger } from "logger";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons

const STEP_CODE = "stepCode";
const STEP_BIRTHDATE = "stepBirthdate";
const STEP_PASSWORD = "stepPassword";

class SignUpFormPatient extends React.Component {
  constructor(props) {
    super(props);

    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        const { password } = this.state;
        return value === password;
      });
    }

    this.state = {
      step: STEP_CODE,
      code: "",
      birthDate: "",
      password: "",
      confirmPassword: "",
      user: null,
    };
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleUserType = (value) => {
    this.setState({ userType: value });
  };

  handleSendCode = () => {};
  handleCode = () => {
    this.setState({ step: STEP_BIRTHDATE });
  };

  handleBirthDate = () => {
    this.setState({ step: STEP_PASSWORD });
  };

  handleCreateAccount = () => {};

  render() {
    const { t } = this.props;
    const { step, code, birthDate, user, password, confirmPassword } = this.state;

    return (
      <div>
        {step === STEP_CODE && (
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleCode();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container justify="center" spacing={8}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{t("fields.patient_code")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.code")} *`}
                  name="code"
                  value={code}
                  size="small"
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[t("errors.required")]}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" fullWidth type="submit" variant="contained">
                  {t("validate")}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <Typography onClick={() => this.handleSendCode}>{t("no_code")}</Typography>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}

        {step === STEP_BIRTHDATE && (
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleBirthDate();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container justify="center" spacing={8}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{t("birth_date_label", { user })}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.birth_date")} *`}
                  name="birthDate"
                  value={birthDate}
                  size="small"
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[t("errors.required")]}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" fullWidth type="submit" variant="contained">
                  {t("validate")}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}

        {step === STEP_PASSWORD && (
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleCreateAccount();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container justify="center" spacing={8}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{t("create_account_password")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.password")} *`}
                  name="password"
                  size="small"
                  type="password"
                  validators={["required"]}
                  errorMessages={[t("errors.required")]}
                  value={password}
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.confirm_password")} *`}
                  name="confirmPassword"
                  size="small"
                  type="password"
                  validators={["required", "isPasswordMatch"]}
                  errorMessages={[t("errors.required"), t("errors.password_mismatch")]}
                  value={confirmPassword}
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" fullWidth type="submit" variant="contained">
                  {t("login")}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        )}
      </div>
    );
  }
}

export default withTranslation()(SignUpFormPatient);
