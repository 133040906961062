import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { logger } from "./logger";

export default function initTracing(React) {
  const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
  let sentryInit = false;
  if (sentryDSN && !sentryInit) {
    try {
      Sentry.init({
        React,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 1.0,
        release: process.env.REACT_APP_RELEASE || "localhost",
        environment: process.env.REACT_APP_NODE_ENV || "development",
      });
    } catch (error) {
      logger.error(error);
    }
    sentryInit = true;
  }
}
