/* eslint-disable class-methods-use-this */
import _ from "lodash";
import { logger } from "../logger";

/*
    This class allows to load a manifest file (JSON by default) referencing the path of all the assets contained in the S3 bucket.
      As it is a singleton, the instance is shared between all importing modules.
    However, it is required to load the manifest at least once to be able to properly use this object.
*/

class AssetResolver {
  initialize(sourceURL, manifestPath) {
    this.state = {
      sourceURL,
      manifestPath,
      manifest: {},
      loadedExtensions: [],
    };
  }

  isURLValid(key) {
    return key.match(/^([^"<>^`{|}]*)/);
  }

  loadManifestAs(extension = "json", parse = true) {
    if (this.isExtensionLoaded(extension)) {
      logger.error("Manifest already loaded with this extension.");
      return new Promise((resolve) => {
        resolve(this.state.manifest[extension]);
      });
    }
    return fetch(`${this.state.sourceURL}${this.state.manifestPath}.${extension}`)
      .then((response) => response.text())
      .then((data) => {
        this.state.loadedExtensions.push(extension);
        if (parse) {
          this.state.manifest[extension] = this.parseManifestAs(data, extension);
        } else {
          this.state.manifest[extension] = data;
        }
        return this.state.manifest[extension];
      })
      .catch((error) => {
        logger.error(`Could not load assets manifest as "${extension}" error.message`, { error });
        return error;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  parseManifestAs(manifest, extension = "json") {
    switch (extension) {
      // XML
      // CSV
      // Anything
      default:
        return JSON.parse(manifest);
    }
  }

  isExtensionLoaded(extension) {
    return _.includes(this.state.loadedExtensions, extension);
  }

  getAsset(key, extension = "json", cached = !process.env.NO_ASSET_CACHE) {
    if (key && key.length) {
      if (!cached) {
        const assetURL = `${this.state.sourceURL}${key[0] === "/" ? "" : "/"}${key}`;
        if (!this.isURLValid(assetURL)) {
          logger.error(`${assetURL} isn't a valid URL`);
        }
        return assetURL;
      }

      if (this.isExtensionLoaded(extension)) {
        const revedAsset = this.state.manifest[extension][key];
        if (!revedAsset) {
          logger.warn(`Can't find reved asset ${key}`);
          return `${this.state.sourceURL}${key[0] === "/" ? "" : "/"}${key}`;
        }
        return `${this.state.sourceURL}${revedAsset[0] === "/" ? "" : "/"}${revedAsset}`;
      }
      logger.error(`Assets manifest isn't loaded as "${extension}"`);
    }
    return null;
  }
}

const AssetResolverInstance = new AssetResolver();
AssetResolverInstance.initialize(process.env.REACT_APP_ASSETS_URL, "/rev-manifest");
window.AssetResolver = AssetResolverInstance;
// Object.freeze(AssetResolverInstance);

export default AssetResolverInstance;
window.AssetResolver.loadManifestAs().then(() => {
  // console.log(window.AssetResolver.getAsset("activitiesAssets/planDeVille/image/Icon.png"));
});
