import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { logger } from "logger";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Snackbar from "components/Snackbar/Snackbar";
import { getApolloClient } from "utils/apolloClientFactory";
import { UPDATE_GROUP, CREATE_GROUP, ADD_USER_TO_GROUP, GET_GROUP_INFOS } from "graphql/queries";
import ROLES from "utils/roles";
import { setCurrentUser, formatDateShort } from "utils";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

class EstablishmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      groupID: "",
      establishmentName: "",
      establishmentPhone: "",
      license: {},
      address: "",
      city: "",
      zip: "",
      snackbar: {
        open: false,
      },
      targetGroupId: props.targetGroupId || null,
    };
  }

  componentDidMount() {
    const groupID = this.state.targetGroupId || (this.state.user && this.state.user.group && this.state.user.group._id);
    this.setState({ groupID });

    if (groupID) {
      getApolloClient().then((client) => {
        client
          .query({
            query: GET_GROUP_INFOS,
            variables: { groupID },
          })
          .then((response) => {
            const group = response.data.getGroupInfos;

            this.setState({
              establishmentName: group.name,
              establishmentPhone: group.phoneNumber || "",
              address: (group.address && group.address.street) || "",
              city: (group.address && group.address.city) || "",
              zip: (group.address && group.address.postCode) || "",
              license: group.licenses.length ? group.licenses[0] : {},
            });
          });
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSaveInfos = () => {
    const { history } = this.props;
    if (this.state.user && this.state.user.role.includes(ROLES.MANAGER) && this.state.user.type) {
      getApolloClient().then((client) => {
        if (this.state.groupID) {
          client
            .mutate({
              mutation: UPDATE_GROUP,
              variables: {
                id: this.state.groupID,
                name: this.state.establishmentName,
                phoneNumber: this.state.establishmentPhone,
                address: {
                  street: this.state.address,
                  city: this.state.city,
                  postCode: this.state.zip,
                },
              },
            })
            .then(() => {
              this.handleSnackbar(true);
            });
        } else {
          client
            .mutate({
              mutation: CREATE_GROUP,
              variables: {
                name: this.state.establishmentName,
                phoneNumber: this.state.establishmentPhone,
                address: {
                  street: this.state.address,
                  city: this.state.city,
                  postCode: this.state.zip,
                },
              },
            })
            .then((response) => {
              client
                .mutate({
                  mutation: ADD_USER_TO_GROUP,
                  variables: {
                    userId: this.state.user._id,
                    groupId: response.data.createGroup._id,
                  },
                })
                .then(() => {
                  setCurrentUser().then(() => {
                    this.handleSnackbar(true);
                    history.push(`/users`);
                    window.location.reload();
                  });
                });
            });
        }
      });
    }
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  isReadOnly = () => {
    const role = this.state.user && this.state.user.role;
    return !role || !role.includes(ROLES.MANAGER);
  };

  render() {
    const { t } = this.props;
    const { user, license, establishmentName, establishmentPhone, address, city, zip, snackbar } = this.state;

    return this.isReadOnly() ? (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          {establishmentName && (
            <Typography variant="body1" gutterBottom>
              {t("fields.establishment.name")} : {establishmentName}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("fields.establishment.phone")} : {establishmentPhone}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("fields.address")} : {address}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("fields.city")} : {city}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("fields.zip")} : {zip}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "50px" }}>
          {license && license.expirationDate && (
            <Typography variant="body1" gutterBottom>
              {t("license")} {t("expiration")} : {formatDateShort(license.expirationDate)}
            </Typography>
          )}
        </Grid>
      </Grid>
    ) : !user || !user.type ? (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("note.complete_profile")}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.update_establishment")}
        />
        <ValidatorForm
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSaveInfos();
          }}
          onError={(errors) => logger.error(errors)}
        >
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label={`${t("fields.establishment.name")} *`}
                    name="establishmentName"
                    value={establishmentName}
                    size="small"
                    variant="outlined"
                    validators={["required"]}
                    errorMessages={[t("errors.required")]}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.establishment.phone")}`}
                name="establishmentPhone"
                value={establishmentPhone}
                size="small"
                variant="outlined"
                validators={["isNumber"]}
                errorMessages={[t("errors.phone_invalid")]}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.address")}`}
                name="address"
                value={address}
                size="small"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.city")}`}
                name="city"
                value={city}
                size="small"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.zip")}`}
                name="zip"
                value={zip}
                size="small"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button color="primary" fullWidth type="submit" variant="contained">
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  }
}

export default withTranslation()(withRouter(EstablishmentForm));
