import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { logger } from "logger";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";

class ActivationEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      sent: false,
      activated: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleActivationEmail = () => {};

  render() {
    const { redirect, history, t } = this.props;
    const { email, sent, activated } = this.state;

    return (
      <Card variant="outlined">
        <CardContent>
          {!sent ? (
            <ValidatorForm
              onSubmit={(e) => {
                e.preventDefault();
                // const state = _.omit(this.state, omit);
                this.handleActivationEmail();

                if (redirect) {
                  history.push(`/profile`);
                }
              }}
              onError={(errors) => logger.error(errors)}
            >
              <Grid container justify="center" spacing={8}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography>{t("forgot_password_label")}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label={`${t("fields.email")} *`}
                    name="email"
                    value={email}
                    size="small"
                    variant="outlined"
                    validators={["required", "isEmail"]}
                    errorMessages={[t("errors.required"), t("errors.email")]}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" fullWidth type="submit" variant="contained">
                    {t("send")}
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          ) : activated ? (
            <Grid container justify="center" spacing={8}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{t("activation_email_activated")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    history.push(`/`);
                  }}
                >
                  {t("go_back_home")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center" spacing={8}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography>{t("activation_email_confirmation")}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    history.push(`/`);
                  }}
                >
                  {t("go_back_home")}
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withTranslation()(withRouter(ActivationEmailForm));
