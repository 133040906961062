import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import SignUpFormPatient from "components/SignUpForm/SignUpFormPatient";
import SignUpFormTherapist from "components/SignUpForm/SignUpFormTherapist";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// @material-ui/icons

const USER_PATIENT = "patient";
const USER_THERAPIST = "therapist";

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: USER_THERAPIST,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleUserType = (value) => {
    this.setState({ userType: value });
  };

  render() {
    const { t } = this.props;
    const { userType } = this.state;

    return (
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12}>
              <Typography variant="h4" align="center" gutterBottom>
                {t("titles.sign_up")}
              </Typography>
            </Grid>
            {!userType && (
              <div>
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "center" }} color="textSecondary">
                    {t("fields.user_type")}
                  </Typography>
                </Grid>
                <Grid container item xs={12} justify="space-between" spacing={8}>
                  <Grid item xs={6}>
                    <Button color="primary" fullWidth type="submit" variant="contained" onClick={() => this.handleUserType(USER_PATIENT)}>
                      {t("roles.patient")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button color="primary" fullWidth type="submit" variant="contained" onClick={() => this.handleUserType(USER_THERAPIST)}>
                      {t("roles.therapist")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
            {userType === USER_PATIENT && <SignUpFormPatient />}
            {userType === USER_THERAPIST && <SignUpFormTherapist />}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

SignUpForm.propTypes = {
  history: PropTypes.any,
  redirect: PropTypes.any,
  // mutation: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
  history: null,
  redirect: null,
};

export default withTranslation()(SignUpForm);
