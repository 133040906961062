import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ROLES from "utils/roles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { logger } from "logger";
import Button from "@material-ui/core/Button";
import ModalForm from "components/Modal/ModalForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PROFESSIONS } from "utils/constants";
import theme from "styles/theme";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SimpleYesNoDialog from "components/Dialog/SimpleYesNoDialog";
import Snackbar from "components/Snackbar/Snackbar";
import LicensePicker from "components/LicensePicker/LicensePicker";
import { getApolloClient } from "utils/apolloClientFactory";
import {
  ATTRIBUTE_LICENSE,
  DELETE_USER,
  REMOVE_LICENSE,
  UPDATE_USER,
  UPDATE_USER_ROLE,
  GET_NB_LICENSES,
  RESEND_MAIL_USER,
  ATTRIBUTE_TARGET_LICENSE,
} from "graphql/queries";
import { formatDateLong } from "utils";
import Checkbox from "@material-ui/core/Checkbox";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    const { user, t } = props;

    this.state = {
      currentUser: window.covirtua_current_user,
      userId: user._id,
      email: user.email,
      role: user.role,
      isUser: user.role.includes(ROLES.USER),
      isSupervisor: user.role.includes(ROLES.SUPERVISOR),
      isManager: user.role.includes(ROLES.MANAGER),
      isAdmin: user.role.includes(ROLES.ADMIN),
      firstName: user.firstName,
      lastName: user.lastName,
      profession: user.profession,
      service: user.service,
      phoneNumber: user.phoneNumber,
      lastLogin: user.lastLogin,
      status: user.lastLogin ? t("account_active") : t("account_non_active"),
      license: (user.license && user.license._id) || "",
      delete_user: false,
      add_license: false,
      delete_license: false,
      snackbar: {
        open: false,
      },
      snackbarMail: {
        open: false,
      },
      licensePicker: {
        open: false,
      },
    };
  }

  getNbLicenses = () => {
    getApolloClient().then((client) => {
      client
        .query({
          query: GET_NB_LICENSES,
          variables: {
            groupId: this.state.currentUser.group._id,
          },
        })
        .then((response) => {
          this.setState({
            nbLicensesAvailable: response.data.getNumberOfAvailableLicenses,
          });
        });
    });
  };

  handleChange = (event) => {
    if (event.target.type === "checkbox") {
      this.setState({ [event.target.name]: event.target.checked });
      if (this.state.isUser) {
        this.setState({ isSupervisor: false });
      }
      if (this.state.isSupervisor) {
        this.setState({ isUser: false });
      }
    } else this.setState({ [event.target.name]: event.target.value });
  };

  handleEditUser = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            userId: this.props.user._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            profession: this.state.profession,
            service: this.state.service,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
          },
        })
        .then((response) => {
          client.mutate({
            mutation: UPDATE_USER_ROLE,
            variables: {
              userId: this.props.user._id,
              role: [
                this.state.isAdmin ? ROLES.ADMIN : null,
                this.state.isManager ? ROLES.MANAGER : null,
                this.state.isSupervisor ? ROLES.SUPERVISOR : null,
                this.state.isUser ? ROLES.USER : null,
              ].filter((elem) => elem),
            },
          });
          this.handleSnackbar(true);
          this.handleClose();
        });
    });
  };

  handleLicensePicker = (bool) => {
    this.setState((prevState) => ({
      licensePicker: {
        ...prevState.licensePicker,
        open: bool,
      },
    }));
  };

  handleDeleteUser = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: DELETE_USER,
          variables: {
            userId: this.state.userId,
          },
        })
        .then((response) => {
          this.handleModalDeleteUser(false);
          this.handleClose();
        });
    });
  };

  handleDeleteLicense = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: REMOVE_LICENSE,
          variables: {
            groupId: this.props.groupId,
            licenseId: this.state.license,
          },
        })
        .then((response) => {
          console.log(response);
          this.handleModalDeleteLicense(false);
          this.handleClose();
        });
    });
  };

  handleAddLicense = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: ATTRIBUTE_LICENSE,
          variables: {
            groupId: this.props.groupId,
            userId: this.props.user._id,
          },
        })
        .then((responseAttributeLicense) => {
          this.handleModalAddLicense(false);
          this.handleClose();
        });
    });
  };

  handleModalDeleteUser = (bool) => {
    this.setState({ delete_user: bool });
  };

  handleSendMailAgainToUser = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: RESEND_MAIL_USER,
          variables: {
            userId: this.props.user._id,
          },
        })
        .then((response) => {
          this.handleSnackbarMail(true);
        });
    });
  };

  handleModalAddLicense = (bool) => {
    this.setState({ add_license: bool });
  };

  handleModalDeleteLicense = (bool) => {
    this.setState({ delete_license: bool });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  handleSnackbarMail = (bool) => {
    this.setState((prevState) => ({
      snackbarMail: {
        ...prevState.snackbarMail,
        open: bool,
      },
    }));
  };

  addLicense = (license) => {
    if (license) {
      getApolloClient().then((client) => {
        client
          .mutate({
            mutation: ATTRIBUTE_TARGET_LICENSE,
            variables: {
              licenseId: license._id,
              userId: this.props.user._id,
            },
          })
          .then((responseAttributeLicense) => {
            this.setState({
              license: responseAttributeLicense.data.attributeTargetLicenseTo._id,
            });
          })
          .catch((error) => {
            logger.error(error);
          });
      });
    }
  };

  componentDidMount() {
    this.getNbLicenses();
  }

  render() {
    const { t, open } = this.props;
    const {
      email,
      firstName,
      lastName,
      profession,
      service,
      phoneNumber,
      lastLogin,
      license,
      status,
      delete_user,
      add_license,
      delete_license,
      snackbar,
      snackbarMail,
      licensePicker,
    } = this.state;

    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.update_account")}
        />

        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbarMail.open}
          onClose={() => this.handleSnackbarMail(false)}
          message={t(`Mail envoyé à ${email}`)}
        />

        <LicensePicker
          open={licensePicker.open}
          onClose={(license) => {
            this.addLicense(license);
            this.handleLicensePicker(false);
          }}
        />

        <ModalForm open={open} onClose={this.handleClose} title={t("titles.edit_user")}>
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleEditUser();
              this.handleClose();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container spacing={16} justify="center">
              <Grid item xs={12}>
                <TextValidator
                  disabled
                  fullWidth
                  label={`${t("fields.email")} *`}
                  name="email"
                  value={email}
                  size="small"
                  variant="outlined"
                  validators={["required", "isEmail"]}
                  errorMessages={[t("errors.required"), t("errors.email")]}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label={t("roles.user")}
                  control={<Checkbox checked={this.state.isUser} onChange={this.handleChange} value="isUser" name="isUser" />}
                />
                <FormControlLabel
                  label={t("roles.supervisor")}
                  control={
                    <Checkbox checked={this.state.isSupervisor} onChange={this.handleChange} value="isSupervisor" name="isSupervisor" />
                  }
                />
                <FormControlLabel
                  label={t("roles.manager")}
                  control={<Checkbox checked={this.state.isManager} onChange={this.handleChange} value="isManager" name="isManager" />}
                />
                {this.state.currentUser.role.includes(ROLES.ADMIN) && (
                  <FormControlLabel
                    label={t("roles.admin")}
                    control={<Checkbox checked={this.state.isAdmin} onChange={this.handleChange} value="isAdmin" name="isAdmin" />}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.first_name")}`}
                  name="firstName"
                  value={firstName}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.last_name")}`}
                  name="lastName"
                  value={lastName}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ marginLeft: "15px" }}>{t("fields.professional.profession")}</InputLabel>
                  <Select
                    value={profession}
                    onChange={this.handleChange}
                    input={<OutlinedInput labelWidth={profession} name="profession" id="profession" />}
                  >
                    {PROFESSIONS.map((attribute) => (
                      <MenuItem value={attribute.value}>{t(attribute.intl)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.service")}`}
                  name="service"
                  value={service}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.phone")}`}
                  name="phoneNumber"
                  value={phoneNumber}
                  size="small"
                  variant="outlined"
                  validators={["isNumber"]}
                  errorMessages={[t("errors.phone_invalid")]}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item container justify="flex-start" alignItems="center" spacing={16}>
                <Grid item>
                  {t("license")} : {license}
                </Grid>

                {license ? (
                  <Grid item>
                    <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleModalDeleteLicense(true)}>
                      {t("delete_license")}
                    </Button>
                    <SimpleYesNoDialog
                      content={t("dialog.delete_license_content")}
                      title={t("dialog.delete_license_title", { email })}
                      yes={this.handleDeleteLicense}
                      no={() => this.handleModalDeleteLicense(false)}
                      onClose={() => this.handleModalDeleteLicense(false)}
                      open={delete_license}
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                      disabled={this.state.nbLicensesAvailable === "0"}
                      onClick={() => {
                        this.handleLicensePicker(true);
                      }}
                    >
                      {t("assign_license")}
                    </Button>
                    <SimpleYesNoDialog
                      content={t("dialog.add_license_content")}
                      title={t("dialog.add_license_title", { email })}
                      yes={this.handleAddLicense}
                      no={() => this.handleModalAddLicense(false)}
                      onClose={() => this.handleModalAddLicense(false)}
                      open={add_license}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                {t("status")} : {status}
              </Grid>

              <Grid item xs={12}>
                {t("last_connection")} : {lastLogin ? formatDateLong(lastLogin) : "-"}
              </Grid>

              <Grid item container justify="space-around" style={{ marginTop: theme.spacing.unit * 2 }}>
                <Grid item>
                  <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleModalDeleteUser(true)}>
                    {t("delete")}
                  </Button>
                  <SimpleYesNoDialog
                    content={t("dialog.delete_user_content")}
                    title={t("dialog.delete_user_title", {
                      email,
                    })}
                    yes={this.handleDeleteUser}
                    no={() => this.handleModalDeleteUser(false)}
                    onClose={() => this.handleModalDeleteUser(false)}
                    open={delete_user}
                  />
                </Grid>

                <Grid item>
                  <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleSendMailAgainToUser()}>
                    {t("Renvoyer mail")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button color="primary" fullWidth type="submit" variant="contained">
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </ModalForm>
      </div>
    );
  }
}

export default withTranslation()(EditUser);
