import React from "react";
import { Navigation } from "react-minimal-side-navigation";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import ROLES from "utils/roles";
import "./NavigationSideBar.css";

class NavigationSideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      items: [],
      ready: false,
    };
  }

  addManagerLinks = () => {
    const { t } = this.props;

    this.setState({
      items: [
        {
          title: t("navigation.profile"),
          itemId: "/profile",
        },
        {
          title: t("navigation.establishment"),
          itemId: "/establishment",
        },
        {
          title: t("navigation.users"),
          itemId: "/users",
        },
        {
          title: t("navigation.licenses"),
          itemId: "/licenses",
        },
      ],
    });
  };
  addAdminLinks = () => {
    const { t } = this.props;

    this.setState({
      items: [
        {
          title: t("navigation.profile"),
          itemId: "/profile",
        },
        {
          title: t("navigation.establishment"),
          itemId: "/establishment",
        },
        {
          title: t("navigation.users"),
          itemId: "/users",
        },
        {
          title: t("navigation.licenses"),
          itemId: "/licenses",
        },
        {
          title: t("navigation.groups"),
          itemId: "/groups",
        },
      ],
    });
  };
  addLinks = () => {
    const { t } = this.props;

    this.setState({
      items: [
        {
          title: t("navigation.profile"),
          itemId: "/profile",
        },
        {
          title: t("navigation.establishment"),
          itemId: "/establishment",
        },
      ],
    });
  };

  componentDidMount() {
    const manage = () => {
      const user = window.covirtua_current_user;
      // manager with profile and establishment completed or admin
      if (user && user.role.includes(ROLES.ADMIN)) {
        this.addAdminLinks();
      } else if (user && user.role.includes(ROLES.MANAGER) && user.type) this.addManagerLinks();
      else {
        this.addLinks();
      }
      this.setState({ ready: true });
    };
    manage();
  }

  handleLogOut = () => {
    const { history } = this.props;
    history.push("/profile");
    window.keycloak.logout();
  };

  render() {
    const { location, history, t } = this.props;
    const { items, ready } = this.state;

    return (
      items.length > 0 &&
      ready && (
        <Grid item className="container">
          <img src="/logo.png" className="logo" alt="logo covirtua" />
          <div className="navigation">
            <Navigation
              activeItemId={location.pathname}
              onSelect={({ itemId }) => {
                history.push(itemId);
              }}
              items={items}
            />
          </div>
          <div className="logout" onClick={this.handleLogOut}>
            {t("Main_Disconnect")}
          </div>
        </Grid>
      )
    );
  }
}

export default withTranslation()(withRouter(NavigationSideBar));
