import React from "react";
import LicensesSummary from "components/LicensesSummary/LicensesSummary";

class Licenses extends React.Component {
  render() {
    return <LicensesSummary />;
  }
}

export default Licenses;
