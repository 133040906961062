import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Person from "@material-ui/icons/Person";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import headerLinksStyle from "assets/jss/components/headerLinksStyle.jsx";
import { getDoctorName } from "utils";

const styles = (theme) => ({
  profileButton: {
    fontWeight: "600",
    color: "#555555",
  },
  noUpperCase: {
    textTransform: "unset",
  },
});

class HeaderLinks extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleGotoPatients = () => {
    this.setState({ open: false });
    window.open(process.env.REACT_APP_URL, "_blank");
  };

  handleLogOut = () => {
    const { history } = this.props;
    window.keycloak.logout();
    history.push("/");
  };

  render() {
    const { classes, t } = this.props;
    const { open } = this.state;
    const currentUser = window.covirtua_current_user;

    return (
      <div className={classes.manager}>
        <Button
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          color={window.innerWidth > 959 ? "inherit" : "default"}
          justicon={window.innerWidth > 959 ? "true" : "false"}
          simple={!(window.innerWidth > 959) ? "true" : "false"}
          aria-owns={open ? "menu-list-grow" : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
          className={classNames(classes.buttonLink, classes.profileButton, classes.noUpperCase)}
        >
          <Person className={classes.icons} />
          {getDoctorName(currentUser)}
          <Hidden mdUp implementation="css">
            <p onClick={this.handleClick} className={classes.linkText}>
              Profile
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={`${classNames({ [classes.popperClose]: !open })} ${classes.pooperNav}`}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role="menu">
                    <MenuItem onClick={this.handleGotoPatients} className={classes.dropdownItem}>
                      {t("Main_App")}
                    </MenuItem>
                    <MenuItem onClick={this.handleLogOut} className={classes.dropdownItem}>
                      {t("Main_Disconnect")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(styles)(withStyles(headerLinksStyle)(withRouter(HeaderLinks))));
