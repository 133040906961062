import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { ValidatorForm } from "react-material-ui-form-validator";
import { logger } from "logger";
import Button from "@material-ui/core/Button";
import ModalForm from "components/Modal/ModalForm";
import SimpleYesNoDialog from "components/Dialog/SimpleYesNoDialog";
import Snackbar from "components/Snackbar/Snackbar";
import { getApolloClient } from "utils/apolloClientFactory";
import { ADD_LICENSE, CREATE_LICENSE, DELETE_LICENSES } from "graphql/queries";
import moment from "moment";
import { TextField } from "@material-ui/core";
import TagPicker from "components/TagPicker/TagPicker";

class ManageLicenses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nbLicenseAdd: 1,
      nbMonthsAdd: 36,
      nbLicenseDelete: 0,
      add_license: false,
      delete_license: false,
      license_flags: ["covirtua_cognition"],
      edit_tags: false,
      snackbar: {
        open: false,
      },
    };
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") this.setState({ [event.target.name]: event.target.checked });
    else this.setState({ [event.target.name]: event.target.value });
  };

  handleModalEditTags = (bool) => {
    this.setState({ edit_tags: bool });
  };


  handleAddLicense = () => {
    const { group } = this.props;
    const months = this.state.nbMonthsAdd >= 0 ? this.state.nbMonthsAdd : 0;

    for (let i = 0; i < this.state.nbLicenseAdd; i++) {
      getApolloClient().then((client) => {
        client
          .mutate({
            mutation: CREATE_LICENSE,
            variables: {
              expirationDate: moment().add(months, "months").endOf("month").endOf("day").toString(),
              flags: this.state.license_flags,
            },
          })
          .then((responseCreateLicense) => {
            client.mutate({
              mutation: ADD_LICENSE,
              variables: {
                groupId: group._id,
                licenseId: responseCreateLicense.data.createLicense._id,
              },
            });
          });
      });
    }

    this.handleSnackbar(true);
    this.handleModalAddLicense(false);
    this.handleClose();
  };

  handleDeleteLicense = () => {
    const { group } = this.props;

    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: DELETE_LICENSES,
          variables: {
            groupId: group._id,
            nb: parseInt(this.state.nbLicenseDelete),
          },
        })
        .then(() => {
          this.handleSnackbar(true);
          this.handleModalDeleteLicense(false);
          this.handleClose();
        });
    });
  };

  handleModalAddLicense = (bool) => {
    this.setState({ add_license: bool });
  };

  handleModalDeleteLicense = (bool) => {
    this.setState({ delete_license: bool });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  render() {
    const { t, open, group } = this.props;
    const { add_license, delete_license, nbLicenseAdd, nbMonthsAdd, nbLicenseDelete, snackbar, edit_tags } = this.state;

    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.success")}
        />

        <ModalForm open={open} onClose={this.handleClose} title={t("titles.edit_group")}>
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleAddLicense();
              this.handleClose();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container spacing={16} justify="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`${t("nb_licenses")}`}
                  name="nbLicenseAdd"
                  value={nbLicenseAdd}
                  size="small"
                  onChange={this.handleChange}
                />
                <TextField
                  fullWidth
                  label={`${t("nb_months")}`}
                  name="nbMonthsAdd"
                  value={nbMonthsAdd}
                  size="small"
                  onChange={this.handleChange}
                />
                <Button color="primary" variant="outlined" onClick={() => this.handleModalEditTags(true)}>
                  {t("modify_licenses_flags")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" fullWidth variant="contained" onClick={() => this.handleModalAddLicense(true)}>
                  {t("add_licenses")}
                </Button>
                <SimpleYesNoDialog
                  content={t("dialog.add_licenses_content", {
                    nb: nbLicenseAdd,
                  })}
                  title={t("dialog.add_licenses_title", {
                    group: group.name,
                  })}
                  yes={this.handleAddLicense}
                  no={() => this.handleModalAddLicense(false)}
                  onClose={() => this.handleModalAddLicense(false)}
                  open={add_license}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`${t("nb_licenses")}`}
                  name="nbLicenseDelete"
                  value={nbLicenseDelete}
                  size="small"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" fullWidth variant="contained" onClick={() => this.handleModalDeleteLicense(true)}>
                  {t("delete_licenses")}
                </Button>
                <SimpleYesNoDialog
                  content={t("dialog.delete_licenses_content", {
                    nb: nbLicenseDelete,
                  })}
                  title={t("dialog.delete_licenses_title", {
                    group: group.name,
                  })}
                  yes={this.handleDeleteLicense}
                  no={() => this.handleModalDeleteLicense(false)}
                  onClose={() => this.handleModalDeleteLicense(false)}
                  open={delete_license}
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </ModalForm>
        {edit_tags && <TagPicker open={edit_tags} license_flags={this.state.license_flags} onClose={(test) => { this.setState({ license_flags: test }); this.handleModalEditTags(false) }} />}
      </div>
    );
  }
}

export default withTranslation()(ManageLicenses);
