import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { logger } from "logger";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import theme from "styles/theme";
import { HEALTH_FACILITY_USER, PROFESSIONAL_USER } from "utils/constants";

class SignUpFormTherapist extends React.Component {
  constructor(props) {
    super(props);

    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        const { password } = this.state;
        return value === password;
      });
    }

    this.state = {
      therapistType: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      establishmentName: "",
      establishmentPhone: "",
      profession: "",
      rpps: "",
      address: "",
      city: "",
      zip: "",
      password: "",
      confirmPassword: "",
      cguCgv: false,
      reCaptcha: false,
      sent: false,
    };
  }

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") this.setState({ [event.target.name]: event.target.checked });
    else this.setState({ [event.target.name]: event.target.value });
  };

  handleSignUpTherapist = () => {};

  handleSendAgain = () => {};

  render() {
    const { redirect, history, t } = this.props;
    const {
      therapistType,
      firstName,
      lastName,
      email,
      phone,
      password,
      establishmentName,
      establishmentPhone,
      profession,
      rpps,
      address,
      city,
      zip,
      confirmPassword,
      cguCgv,
      // reCaptcha,
      sent,
    } = this.state;

    return (
      <div>
        {!sent ? (
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              // const state = _.omit(this.state, omit);
              this.handleSignUpTherapist();

              if (redirect) {
                history.push(`/profile`);
              }
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container spacing={8}>
              <Grid item>
                <RadioGroup name="therapistType" value={therapistType} onChange={this.handleChange} style={{ display: "inline" }}>
                  <FormControlLabel
                    value={HEALTH_FACILITY_USER}
                    control={<Radio required />}
                    label={t("fields.health_facility_user")}
                    style={{
                      marginRight: theme.spacing.unit * 4,
                    }}
                  />
                  <FormControlLabel value={PROFESSIONAL_USER} control={<Radio required />} label={t("fields.professional_user")} />
                </RadioGroup>
              </Grid>

              {therapistType && (
                <Grid container item xs={12} spacing={16}>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.first_name")}`}
                      name="firstName"
                      value={firstName}
                      size="small"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.last_name")}`}
                      name="lastName"
                      value={lastName}
                      size="small"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.email")} *`}
                      name="email"
                      value={email}
                      size="small"
                      variant="outlined"
                      validators={["required", "isEmail"]}
                      errorMessages={[t("errors.required"), t("errors.email")]}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.phone")}`}
                      name="phone"
                      value={phone}
                      size="small"
                      variant="outlined"
                      validators={["isNumber"]}
                      errorMessages={[t("errors.phone_invalid")]}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  {therapistType === HEALTH_FACILITY_USER && (
                    <Grid item xs={12}>
                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            label={`${t("fields.establishment.name")} *`}
                            name="establishmentName"
                            value={establishmentName}
                            size="small"
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={[t("errors.required")]}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            label={`${t("fields.establishment.phone")}`}
                            name="establishmentPhone"
                            value={establishmentPhone}
                            size="small"
                            variant="outlined"
                            validators={["isNumber"]}
                            errorMessages={[t("errors.phone_invalid")]}
                            onChange={this.handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {therapistType === PROFESSIONAL_USER && (
                    <Grid item xs={12}>
                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            label={`${t("fields.professional.profession")} *`}
                            name="profession"
                            value={profession}
                            size="small"
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={[t("errors.required")]}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextValidator
                            fullWidth
                            label={`${t("fields.professional.rpps")} *`}
                            name="rpps"
                            value={rpps}
                            size="small"
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={[t("errors.required")]}
                            onChange={this.handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.address")}`}
                      name="address"
                      value={address}
                      size="small"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.city")}`}
                      name="city"
                      value={city}
                      size="small"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.zip")}`}
                      name="zip"
                      value={zip}
                      size="small"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.password")} *`}
                      name="password"
                      size="small"
                      type="password"
                      validators={["required"]}
                      errorMessages={[t("errors.required")]}
                      value={password}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      label={`${t("fields.confirm_password")} *`}
                      name="confirmPassword"
                      size="small"
                      type="password"
                      validators={["required", "isPasswordMatch"]}
                      errorMessages={[t("errors.required"), t("errors.password_mismatch")]}
                      value={confirmPassword}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox checked={cguCgv} value="isChecked" onChange={this.handleChange} name="cguCgv" />}
                      label={t("fields.accept_cgu_cgv")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button color="primary" fullWidth type="submit" variant="contained">
                      {t("validate")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ValidatorForm>
        ) : (
          <Grid container justify="center" spacing={24}>
            <Grid item xs={12} align="center">
              <Typography>{t("create_account_confirmation", { email })}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                onClick={() => {
                  this.handleSendAgain();
                }}
              >
                {t("send_again_email")}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default withTranslation()(SignUpFormTherapist);
