import React from "react";
import GroupsSummary from "components/GroupsSummary/GroupsSummary";

class Groups extends React.Component {
  render() {
    return <GroupsSummary />;
  }
}

export default Groups;
