/* eslint-disable react/jsx-no-bind */
import React from "react";
import UserProfileForm from "components/UserProfileForm/UserProfileForm";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";

class UserProfile extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Grid container spacing={40} direction="column" justify="center" alignItems="center">
          <Grid item>
            <Typography variant="h4">{t("titles.profile")}</Typography>
          </Grid>
          <Grid item md={6} xs={10} style={{}}>
            <UserProfileForm />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(UserProfile);
