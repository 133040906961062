import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { logger } from "logger";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Snackbar from "components/Snackbar/Snackbar";
import { getApolloClient } from "utils/apolloClientFactory";
import { UPDATE_USER_ROLE, UPDATE_USER } from "graphql/queries";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { HEALTH_FACILITY_USER, PROFESSIONAL_USER, PROFESSIONS } from "utils/constants";
import Radio from "@material-ui/core/Radio";
import theme from "styles/theme";
import ROLES from "utils/roles";
import { setCurrentUser } from "utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

class UserProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      service: "",
      profession: "",
      rpps: "",
      role: "",
      type: "",
      therapistType: "",
      snackbar: {
        open: false,
      },
      ready: false,
    };
  }

  setUser = async () => {
    const user = window.covirtua_current_user;
    this.setState({
      user,
    });
    if (user) {
      this.setState({
        _id: user._id,
        role: user.role,
        type: user.type,
        therapistType: user.type,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        profession: user.profession,
        service: user.service,
        rpps: user.rpps,
        phoneNumber: user.phoneNumber,
      });
    }

    if (this.isNewManager()) {
      const keycloakUser = await window.keycloak.loadUserInfo();
      this.setState({
        firstName: keycloakUser.given_name,
        lastName: keycloakUser.family_name,
        email: keycloakUser.email,
      });
    }

    this.setState({
      ready: true,
    });
  };

  componentDidMount() {
    this.setUser();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSaveInfos = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            userId: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            type: this.state.type,
            email: this.state.email,
            service: this.state.service,
            profession: this.state.profession,
            rpps: this.state.type === PROFESSIONAL_USER ? this.state.rpps : "",
            phoneNumber: this.state.phoneNumber,
          },
        })
        .then(() => {
          if (this.isNewManager() && !this.state.role.includes(ROLES.MANAGER)) {
            getApolloClient().then((client) => {
              client.mutate({
                mutation: UPDATE_USER_ROLE,
                variables: {
                  userId: this.state._id,
                  role: [...this.state.role, ROLES.MANAGER],
                },
              });
            });
          }
          setCurrentUser();
          this.handleSnackbar(true);
        });
    });
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  isNewManager = () => !this.state.user || (this.state.user && !this.state.user.type);

  isTherapistLiberal = () => this.state.type === PROFESSIONAL_USER;

  isUser = () => {
    const role = this.state.role;
    return role.includes(ROLES.USER) || role.includes(ROLES.SUPERVISOR);
  };

  render() {
    const { t } = this.props;
    const { ready, firstName, lastName, email, phoneNumber, type, service, profession, rpps, snackbar } = this.state;

    return ready ? (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.update_profile")}
        />

        <ValidatorForm
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSaveInfos();
          }}
          onError={(errors) => logger.error(errors)}
        >
          <Grid container spacing={8}>
            {this.isNewManager() && (
              <Grid item>
                <RadioGroup name="type" value={type} onChange={this.handleChange} style={{ display: "inline" }}>
                  <FormControlLabel
                    value={HEALTH_FACILITY_USER}
                    control={<Radio required />}
                    label={t("fields.health_facility_user")}
                    style={{
                      marginRight: theme.spacing.unit * 4,
                    }}
                  />
                  <FormControlLabel value={PROFESSIONAL_USER} control={<Radio required />} label={t("fields.professional_user")} />
                </RadioGroup>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.email")} *`}
                name="email"
                value={email}
                size="small"
                readOnly={true}
                variant="outlined"
                disabled
                validators={["required", "isEmail"]}
                errorMessages={[t("errors.required"), t("errors.email")]}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.first_name")}`}
                name="firstName"
                value={firstName}
                size="small"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.last_name")}`}
                name="lastName"
                value={lastName}
                size="small"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                label={`${t("fields.phone")}`}
                name="phoneNumber"
                value={phoneNumber}
                size="small"
                variant="outlined"
                validators={["isNumber"]}
                errorMessages={[t("errors.phone_invalid")]}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ marginLeft: "15px" }}>{t("fields.professional.profession")}</InputLabel>
                <Select
                  value={profession}
                  onChange={this.handleChange}
                  input={<OutlinedInput labelWidth={profession} name="profession" id="profession" />}
                >
                  {PROFESSIONS.map((attribute) => (
                    <MenuItem value={attribute.value}>{t(attribute.intl)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {this.isUser() && (
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.service")}`}
                  name="service"
                  value={service}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>
            )}

            {/*              {this.isTherapistLiberal() &&
              <Grid item xs={12}>
                <TextValidator fullWidth label={`${t("fields.professional.profession")} *`}
                               name="profession"
                               value={profession}
                               size="small"
                               variant="outlined"
                               validators={['required']}
                               errorMessages={[t("errors.required")]}
                               onChange={this.handleChange}/>
              </Grid>
              } */}
            {this.isTherapistLiberal() && (
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.professional.rpps")} *`}
                  name="rpps"
                  value={rpps}
                  size="small"
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[t("errors.required")]}
                  onChange={this.handleChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button color="primary" fullWidth type="submit" variant="contained">
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    ) : (
      <div />
    );
  }
}

export default withTranslation()(UserProfileForm);
