import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow, TableHead, Table } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { getApolloClient } from "utils/apolloClientFactory";
import { SET_LICENSE_FLAGS } from "graphql/queries";
import ModalForm from "components/Modal/ModalForm";
import _ from "lodash";
import Snackbar from "components/Snackbar/Snackbar";

class TagPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      license: props.license,
      license_flags: props.license_flags,
      availableTags: Object.keys(window.featureFlagsMatchingTable.matching_table),
      selectedTags: (props.license) ? props.license.flags : props.license_flags,
      snackbar: {
        open: false,
      },
    };
  }

  componentDidMount() {}

  handleClose = () => {
    this.props.onClose();
  };

  isTagSelected = (tag) => _.find(this.state.selectedTags, (t) => t === tag) !== undefined;

  selectTag = (tag) => {
    let tags_tmp = this.state.selectedTags;
    if (this.isTagSelected(tag)) {
      tags_tmp = _.filter(tags_tmp, (t) => t !== tag);
    } else {
      tags_tmp.push(tag);
    }
    this.setState({
      selectedTags: tags_tmp,
    });
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  saveTags = () => {
    if (this.state.license && this.state.license._id) {// existing license being edited
      getApolloClient().then((client) => {
        client
          .mutate({
            mutation: SET_LICENSE_FLAGS,
            variables: {
              licenseId: this.state.license._id,
              flags: this.state.selectedTags,
            },
          })
          .then((response) => {
            this.handleSnackbar(true);
            this.props.onClose();
          });
      });
    } else {// creating new license template
      this.props.onClose(this.state.selectedTags);
    }
  };

  render() {
    const { t, open } = this.props;
    const { snackbar } = this.state;

    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.update_account")}
        />

        <ModalForm open={open} onClose={this.handleClose} title={t("Sélectionnez les permissions pour cette licence")}>
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Tags</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.availableTags.map((tag) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Checkbox
                          checked={this.isTagSelected(tag)}
                          onChange={() => {
                            this.selectTag(tag);
                          }}
                        />
                      </TableCell>

                      <TableCell>{tag}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item>
              <Button color="primary" fullWidth variant="contained" onClick={() => this.saveTags()}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </ModalForm>
      </div>
    );
  }
}

export default withTranslation()(TagPicker);
