import React from "react";
import UsersSummary from "components/UsersSummary/UsersSummary";

class Users extends React.Component {
  render() {
    return <UsersSummary />;
  }
}

export default Users;
