import { getApolloClient } from "utils/apolloClientFactory";
import { ME } from "graphql/queries";
import moment from "moment";

export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

export function ensureAccessToken() {
  if (!window.keycloak) {
    return new Promise((success) => {
      success();
    });
  }
  return window.keycloak.updateToken(window.keycloak.tokenParsed.exp / 2);
}

export function getBaseUrl() {
  const baseUrlTmp = window.location.href.split("/");
  const baseUrl = `${baseUrlTmp[0]}//${baseUrlTmp[2]}`;
  return baseUrl;
}

export async function setCurrentUser() {
  const client = await getApolloClient();
  return client
    .query({
      query: ME,
    })
    .then(async (response) => {
      const user = response.data.me;
      window.covirtua_current_user = user;
    });
}

export function getDoctorName(doctor) {
  if (doctor) {
    if ((doctor.firstName && doctor.firstName !== "") || (doctor.lastName && doctor.lastName !== "")) {
      return `${doctor.firstName} ${doctor.lastName.toUpperCase()}`;
    } else if (doctor.email) {
      return `${doctor.email}`;
    }
    return `Thérapeute inconnu`;
  }
  return `Thérapeute inconnu`;
}

export function formatDateLong(date) {
  return date ? moment(date).format("DD/MM/YYYY HH:mm:ss") : "";
}

export function formatDateShort(date) {
  return date ? moment(date).format("DD/MM/YY") : "";
}
