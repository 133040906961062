/* eslint-disable react/jsx-no-bind */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingPage extends React.Component {
  render() {
    return (
      <GridContainer direction="column" justify="center" alignItems="center">
        <GridItem
          md={6}
          xs={10}
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", height: 60 }}>
            <CircularProgress />
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

export default LoadingPage;
