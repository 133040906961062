import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import ModalForm from "components/Modal/ModalForm";
import Snackbar from "components/Snackbar/Snackbar";
import EditLicensesOfGroup from "components/EditLicensesOfGroup/EditLicensesOfGroup";
import EstablishmentForm from "components/EstablishmentForm/EstablishmentForm";
import EditUsersOfGroup from "components/EditUsersOfGroup/EditUsersOfGroup";
import ManageLicenses from "components/ManageLicenses/ManageLicenses";

class EditGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbar: {
        open: false,
      },
      group: props.group,
      edit_licenses: false,
      edit_users: false,
      create_licenses: false,
    };
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") this.setState({ [event.target.name]: event.target.checked });
    else this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  handleModalEditLicensesOfGroup = (bool) => {
    this.setState({ edit_licenses: bool });
  };

  handleModalEditUsersOfGroup = (bool) => {
    this.setState({ edit_users: bool });
  };

  handleModalCreateLicensesOfGroup = (bool) => {
    this.setState({ create_licenses: bool });
  };

  render() {
    const { t, open, group } = this.props;
    const { snackbar, edit_licenses, edit_users, create_licenses } = this.state;
    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.success")}
        />

        <ModalForm open={open} onClose={this.handleClose} title={t("titles.edit_group")}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleModalCreateLicensesOfGroup(true)}>
                {t("Générer licences")}
              </Button>
            </Grid>

            <Grid item>
              <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleModalEditLicensesOfGroup(true)}>
                {t("Licences")}
              </Button>
            </Grid>

            <Grid item>
              <Button color="primary" fullWidth variant="outlined" onClick={() => this.handleModalEditUsersOfGroup(true)}>
                {t("Users")}
              </Button>
            </Grid>

            <Grid item>
              <EstablishmentForm targetGroupId={group._id} />
            </Grid>
          </Grid>
        </ModalForm>
        {create_licenses && (
          <ManageLicenses open={create_licenses} group={group} onClose={() => this.handleModalCreateLicensesOfGroup(false)} />
        )}
        {edit_licenses && (
          <EditLicensesOfGroup open={edit_licenses} group={group} onClose={() => this.handleModalEditLicensesOfGroup(false)} />
        )}
        {edit_users && <EditUsersOfGroup open={edit_users} group={group} onClose={() => this.handleModalEditUsersOfGroup(false)} />}
      </div>
    );
  }
}

export default withTranslation()(EditGroup);
