/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "styles/theme";
import "./common.css";
import { logger } from "logger";
import AssetResolver from "./utils/AssetResolver";

window.ACCOUNT_API_URL = process.env.REACT_APP_ACCOUNT_API_URL || "http://localhost:3003";
window.ACTIVITY_SERVER_URL = process.env.REACT_APP_ACTIVITY_SERVER_URL || "http://localhost:8000";

import Sentry from "./sentry";
import "./i18n/i18n-init";
import Layout from "layouts/Layout";

const history = createBrowserHistory();

Sentry(React);

AssetResolver.loadManifestAs().then(() => {
  fetch(window.AssetResolver.getAsset(`/appAssets/config/featureFlagMatchingTable.json`))
    .then((res) => {
      res
        .json()
        .then((featureFlagsConfig) => {
          window.featureFlagsMatchingTable = featureFlagsConfig;
        })
        .catch((parseError) => {
          logger.error(parseError);
        });
    })
    .catch((configError) => {
      logger.error(configError);
    });
});

import(`./config/${process.env.REACT_APP_NODE_ENV}`)
  .then((result) => {
    window.config = result.default;
  })
  .catch((configError) => {
    logger.error(`no config file found for environment : ${process.env.REACT_APP_NODE_ENV}`);
    logger.error(configError);
  })
  .finally(() => {
    ReactDOM.render(
      <div className="common">
        <CssBaseline />
        <Router history={history}>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <Layout />
            </Switch>
          </MuiThemeProvider>
        </Router>
      </div>,

      document.getElementById("root")
    );
  });
