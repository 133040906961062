import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import appLayoutStyle from "assets/jss/layouts/appLayoutStyle.jsx";
import { Route } from "react-router-dom";

const LoginLayoutStructure = ({ children }) => <div>{children}</div>;

const LoginLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <LoginLayoutStructure>
        <Component {...props} />
      </LoginLayoutStructure>
    )}
  />
);

export default withStyles(appLayoutStyle)(LoginLayout);
