import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withTranslation } from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";

class SimpleYesNoDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleYes = () => {
    this.props.yes();
    this.props.onClose();
  };

  handleNo = () => {
    this.props.no();
    this.props.onClose();
  };

  render() {
    const { classes, onClose, title, content, t, ...other } = this.props;

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other} scroll="paper">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleYes} color="secondary" variant={"contained"}>
            {t("confirm")}
          </Button>
          <Button onClick={this.handleNo} color="secondary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SimpleYesNoDialog.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
};

export default withTranslation()(SimpleYesNoDialog);
