import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from "apollo-boost";
import { ensureAccessToken } from "utils";

export function getApolloClient(isDoctor = true) {
  return new Promise((success) => {
    if (isDoctor) {
      ensureAccessToken().then(() => {
        success(
          new ApolloClient({
            link: new ApolloLink((operation, forward) => {
              operation.setContext({
                headers: {
                  authorization: window.keycloak.token ? `Bearer ${window.keycloak.token}` : "",
                },
              });
              return forward(operation);
            }).concat(
              new HttpLink({
                uri: `${window.ACCOUNT_API_URL}/graphql`,
                credentials: "include",
              })
            ),
            fetchOptions: { credentials: "include" },
            cache: new InMemoryCache(),
          })
        );
      });
    } else {
      success(
        new ApolloClient({
          link: new ApolloLink((operation, forward) => {
            operation.setContext({
              headers: {
                authorization: "",
              },
            });
            return forward(operation);
          }).concat(
            new HttpLink({
              uri: `${window.ACCOUNT_API_URL}/graphql`,
              credentials: "include",
            })
          ),
          fetchOptions: { credentials: "include" },
          cache: new InMemoryCache(),
        })
      );
    }
  });
}
