import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import appLayoutStyle from "assets/jss/layouts/appLayoutStyle.jsx";
import authenticatedRoutes from "routes/authenticated";
import AuthenticatedLayout from "layouts/AuthenticatedLayout";
import unAuthenticatedRoutes from "routes/unAuthenticated";
import LoginLayout from "layouts/LoginLayout";
import { Redirect, Switch } from "react-router-dom";
import Keycloak from "keycloak-js";
import { setCurrentUser } from "utils";
import { getApolloClient } from "utils/apolloClientFactory";
import { UPDATE_USER_LAST_LOGIN } from "graphql/queries";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    window.keycloak = new Keycloak(`/keycloak.${process.env.REACT_APP_NODE_ENV || "development"}.json`);

    // checkLoginIframe is true by default but can cause issues in development when altering keycloak's configuration many times (can lead to inconsistent cookies) : https://www.keycloak.org/docs/latest/securing_apps/#session-status-iframe + https://github.com/dasniko/keycloak-reactjs-demo/issues/3#issuecomment-438745122 + https://github.com/ebondu/angular2-keycloak/issues/10 + https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc#session-status-iframe
    const enableCheckLoginIframe = process.env.NODE_ENV !== "development";

    window.keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: enableCheckLoginIframe,
      })
      .then((authenticated) => {
        this.updateToken();
        this.setNewCurrentUser().then(() => {
          this.updateUserLastLogin();
          this.setState({
            userRoles: (window.keycloak.realmAccess && window.keycloak.realmAccess.roles) || [],
            authenticated,
          });
        });
      })
      .catch(() => {
        console.error("failed to initialize");
      });

    window.keycloak.onAuthRefreshSuccess = this.updateToken;
    window.keycloak.onTokenExpired = window.keycloak.updateToken;
  }

  updateToken = () => {
    window.accountUrl = window.keycloak.createAccountUrl();
  };

  setNewCurrentUser = async () => setCurrentUser();

  updateUserLastLogin = () => {
    if (window.covirtua_current_user) {
      const login = new Date(window.keycloak.tokenParsed.auth_time * 1000);

      getApolloClient().then((client) => {
        client.mutate({
          mutation: UPDATE_USER_LAST_LOGIN,
          variables: {
            userId: window.covirtua_current_user._id,
            lastLogin: login,
          },
        });
      });
    }
  };

  render() {
    const { authenticated } = this.state;

    return (
      <div>
        {authenticated ? (
          <Switch>
            {authenticatedRoutes.map((prop) => (
              <AuthenticatedLayout path={prop.path} component={prop.component} key={prop.path} />
            ))}
          </Switch>
        ) : (
          <Switch>
            {unAuthenticatedRoutes.map((prop) => (
              <LoginLayout path={prop.path} component={prop.component} key={prop.path} />
            ))}
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    );
  }
}

export default withStyles(appLayoutStyle)(Layout);
