/* eslint-disable react/jsx-no-bind */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import SignUpForm from "components/SignUpForm/SignUpForm";

class SignUp extends React.Component {
  render() {
    return (
      <div>
        <GridContainer direction="column" justify="center" alignItems="center">
          <GridItem
            md={6}
            xs={10}
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SignUpForm />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default SignUp;
