import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Modal, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "styles/theme";

const styles = (theme) => ({
  paper: {
    position: "absolute",
    left: "50%",
    top: "50%",
    maxHeight: "90vh",
    backgroundColor: "white",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing.unit * 5,
    overflowY: "auto",
  },
});

class ModalForm extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleYes = () => {
    this.props.yes();
    this.props.onClose();
  };

  handleNo = () => {
    this.props.no();
    this.props.onClose();
  };

  render() {
    const { children, classes, onClose, title, content, t, ...other } = this.props;

    return (
      <Modal onClose={this.handleClose} {...other}>
        <div className={classes.paper}>
          <Typography variant={"h4"} style={{ marginBottom: theme.spacing.unit * 3 }}>
            {title}
          </Typography>
          {children}
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.object,
  onClose: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(ModalForm));
