import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { trad as enTrad } from "./en";
import { trad as frTrad } from "./fr";

const resources = {
  en: { translation: enTrad },
  fr: { translation: frTrad },
};

// console.log(resources);

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
  debug: false,
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});
export default i18n;
