import React from "react";
import { withTranslation } from "react-i18next";
import ModalForm from "components/Modal/ModalForm";
import Snackbar from "components/Snackbar/Snackbar";
import UsersSummary from "components/UsersSummary/UsersSummary";

class EditUsersOfGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbar: {
        open: false,
      },
    };
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") this.setState({ [event.target.name]: event.target.checked });
    else this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSnackbar = (bool) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open: bool,
      },
    }));
  };

  render() {
    const { t, open, group } = this.props;
    const { snackbar } = this.state;
    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.success")}
        />

        <ModalForm open={open} onClose={this.handleClose} title={t("titles.edit_group")}>
          <UsersSummary targetGroupId={group._id} />
        </ModalForm>
      </div>
    );
  }
}

export default withTranslation()(EditUsersOfGroup);
