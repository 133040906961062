/* eslint-disable react/jsx-no-bind */
import React from "react";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import ForgotPwdForm from "components/ForgotPwdForm/ForgotPwdForm";

class ForgotPwd extends React.Component {
  render() {
    return (
      <GridContainer direction="column" justify="center" alignItems="center">
        <GridItem
          md={6}
          xs={10}
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ForgotPwdForm />
        </GridItem>
      </GridContainer>
    );
  }
}

export default ForgotPwd;
