import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ROLES from "utils/roles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { logger } from "logger";
import Button from "@material-ui/core/Button";
import ModalForm from "components/Modal/ModalForm";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PROFESSIONS } from "utils/constants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Snackbar from "components/Snackbar/Snackbar";
import { getApolloClient } from "utils/apolloClientFactory";
import { ADD_USER_TO_GROUP, CREATE_USER } from "graphql/queries";
import Checkbox from "@material-ui/core/Checkbox";

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      email: "",
      role: "",
      isUser: false,
      isSupervisor: false,
      isManager: false,
      isAdmin: false,
      firstName: "",
      lastName: "",
      profession: "",
      service: "",
      phoneNumber: "",
      snackbar: {
        open: false,
      },

      errorSnackbar: {
        open: false,
      },
      errorSnackBarMessage: "",

    };
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") this.setState({ [event.target.name]: event.target.checked });
    else this.setState({ [event.target.name]: event.target.value });
  };

  handleAddUser = () => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: CREATE_USER,
          variables: {
            email: this.state.email,
            role: [
              this.state.isAdmin ? ROLES.ADMIN : null,
              this.state.isManager ? ROLES.MANAGER : null,
              this.state.isSupervisor ? ROLES.SUPERVISOR : null,
              this.state.isUser ? ROLES.USER : null,
            ].filter((elem) => elem),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            profession: this.state.profession,
            service: this.state.service,
            phoneNumber: this.state.phoneNumber,
            type: this.state.user.type,
          },
        })
        .then((responseCreateUser) => {
          client
            .mutate({
              mutation: ADD_USER_TO_GROUP,
              variables: {
                userId: responseCreateUser.data.createUser._id,
                groupId: this.state.user.group._id,
              },
            })
            .then((responseAddUser) => {
              this.handleClose(true);
              this.handleSnackbar(true);
            });
        })
        .catch(e => {
          this.handleClose();

          // VERY VERY DIRTY WAY
          // waiting to find a better way to handle apollo errors!
          if (e.graphQLErrors) {
            let groupName = e.message.replace(/(^.*\[|\].*$)/g, '');
            this.handleErrorSnackbar(true, this.state.email, groupName);
          }

        });
    });
  };

  handleClose = (update) => {
    this.props.onClose(update);
  };

  handleSnackbar = (open) => {
    this.setState((prevState) => ({
      snackbar: {
        ...prevState.snackbar,
        open,
      },
    }));
  };

  handleErrorSnackbar = (open, email, group) => {
    let message = this.props.t("snackbar.account_already_assigned", { email: email, group: group });
    this.setState((prevState) => ({
      errorSnackBarMessage: message,
      errorSnackbar: {
        ...prevState.errorSnackbar,
        open,
      },
    }));
  };


  render() {
    const { t, open } = this.props;
    const { email, firstName, lastName, profession, service, phoneNumber, snackbar, errorSnackbar } = this.state;

    return (
      <div>
        <Snackbar
          place="br"
          color="success"
          autoHideDuration={6000}
          open={snackbar.open}
          onClose={() => this.handleSnackbar(false)}
          message={t("snackbar.create_account")}
        />
        {
          <Snackbar
            place="br"
            color="warning"
            autoHideDuration={6000}
            open={errorSnackbar.open}
            onClose={() => this.handleErrorSnackbar(false)}
            message={this.state.errorSnackBarMessage}
          />
        }
        <ModalForm open={open} onClose={this.handleClose} title={t("titles.add_user")}>
          <ValidatorForm
            onSubmit={(e) => {
              e.preventDefault();
              this.handleAddUser();
            }}
            onError={(errors) => logger.error(errors)}
          >
            <Grid container spacing={16} justify="center">
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.email")} *`}
                  name="email"
                  value={email}
                  size="small"
                  variant="outlined"
                  validators={["required", "isEmail"]}
                  errorMessages={[t("errors.required"), t("errors.email")]}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  label={t("roles.user")}
                  control={<Checkbox checked={this.state.isUser} onChange={this.handleChange} value="isUser" name="isUser" />}
                />
                <FormControlLabel
                  label={t("roles.supervisor")}
                  control={
                    <Checkbox checked={this.state.isSupervisor} onChange={this.handleChange} value="isSupervisor" name="isSupervisor" />
                  }
                />
                <FormControlLabel
                  label={t("roles.manager")}
                  control={<Checkbox checked={this.state.isManager} onChange={this.handleChange} value="isManager" name="isManager" />}
                />
                {this.state.user && this.state.user.role.includes(ROLES.ADMIN) && (
                  <FormControlLabel
                    label={t("roles.admin")}
                    control={<Checkbox checked={this.state.isAdmin} onChange={this.handleChange} value="isAdmin" name="isAdmin" />}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.first_name")}`}
                  name="firstName"
                  value={firstName}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.last_name")}`}
                  name="lastName"
                  value={lastName}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ marginLeft: "15px" }}>{t("fields.professional.profession")}</InputLabel>
                  <Select
                    value={profession}
                    onChange={this.handleChange}
                    input={<OutlinedInput labelWidth={profession} name="profession" id="profession" />}
                  >
                    {PROFESSIONS.map((attribute) => (
                      <MenuItem value={attribute.value}>{t(attribute.intl)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.service")}`}
                  name="service"
                  value={service}
                  size="small"
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  label={`${t("fields.phone")}`}
                  name="phoneNumber"
                  value={phoneNumber}
                  size="small"
                  variant="outlined"
                  validators={["isNumber"]}
                  errorMessages={[t("errors.phone_invalid")]}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={4}>
                <Button color="primary" fullWidth type="submit" variant="contained">
                  {t("validate")}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </ModalForm>
      </div>
    );
  }
}

export default withTranslation()(AddUser);
