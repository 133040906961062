import ROLES from "utils/roles";
import UserProfile from "pages/UserProfile/UserProfile";
import Establishment from "pages/Establishment/Establishment";
import Licenses from "pages/Licenses/Licenses";
import Groups from "pages/Groups/Groups";
import Users from "pages/Users/Users";

/**
 * Those are the routes that are displayed after the user is authentified by keycloak
 */

const authenticatedRoutes = [
  {
    path: "/profile",
    component: UserProfile,
  },
  {
    path: "/establishment",
    component: Establishment,
  },
  {
    path: "/users",
    component: Users,
    requiredRoles: [ROLES.ADMIN, ROLES.MANAGER],
  },
  {
    path: "/licenses",
    component: Licenses,
    requiredRoles: [ROLES.ADMIN, ROLES.MANAGER],
  },
  {
    path: "/groups",
    component: Groups,
    requiredRoles: [ROLES.ADMIN],
  },
  {
    path: "/",
    component: UserProfile,
  },
];

export default authenticatedRoutes;
