// import SignIn from "pages/SignIn/SignIn";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import ForgotPwd from "pages/ForgotPwd/ForgotPwd";
import SignUp from "pages/SignUp/SignUp";
import AccountActivation from "pages/AccountActivation/AccountActivation";
import ActivationEmail from "pages/ActivationEmail/ActivationEmail";

/**
 * Those are the routes that are displayed after the user is authentified by keycloak
 */

const unAuthenticatedRoutes = [
  {
    path: "/sign-up",
    component: SignUp,
  },
  {
    path: "/forgot-password",
    component: ForgotPwd,
  },
  {
    path: "/account-activation",
    component: AccountActivation,
  },
  {
    path: "/activation-email",
    component: ActivationEmail,
  },
  {
    path: "/",
    component: LoadingPage,
  },
];

export default unAuthenticatedRoutes;
