import React from "react";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { withTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow, TableHead, Table, Typography, Chip, IconButton } from "@material-ui/core";
import { getApolloClient } from "utils/apolloClientFactory";
import { GET_GROUP, DELETE_LICENSE } from "graphql/queries";
import { formatDateShort } from "utils";
import ROLES from "utils/roles";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TagPicker from "components/TagPicker/TagPicker";

class LicensesSummary extends React.Component {
  constructor(props) {
    super(props);
    const userStored = window.covirtua_current_user;
    this.state = {
      user: userStored,
      isAdmin: userStored && userStored.role.includes(ROLES.ADMIN),
      users: [],
      licenses: [],
      total: 0,
      assigned: 0,
      available: 0,
      total_accounts: 0,
      user_accounts: 0,
      user_licenses: 0,
      user_without_license: 0,
      targetGroupId: this.props.targetGroupId || null,
      selectedLicenses: [],
      edit_tags: false,
    };
  }

  componentDidMount() {
    const groupID = this.state.targetGroupId || (this.state.user && this.state.user.group && this.state.user.group._id);

    if (groupID) {
      getApolloClient().then((client) => {
        client
          .query({
            query: GET_GROUP,
            variables: { groupID },
          })
          .then((response) => {
            const group = response.data.getGroup;
            const findLicense = (id) => group.licenses.find((license) => license.attributedTo && license.attributedTo._id === id);

            this.setState({
              users:
                group.users.map((user) => ({
                  ...user,
                  license: findLicense(user._id),
                })) || [],
              licenses: group.licenses || [],
              total: group.licenses.length,
              assigned: group.licenses.filter((license) => license.attributedTo).length,
              total_accounts: group.users.length,
              user_accounts: group.users.filter((user) => user.role.includes(ROLES.USER)).length,
              user_licenses: group.users.filter((user) => user.license).length,
            });
            this.setState({
              user_without_license: this.state.users.filter((user) => !user.license).length,
              available: this.state.total - this.state.assigned,
            });
          });
      });
    }
  }

  formatName(user) {
    return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email || "";
  }

  noAccess = () => !this.state.user || !this.state.user.group;

  handleDeleteLicense = (id) => {
    getApolloClient().then((client) => {
      client
        .mutate({
          mutation: DELETE_LICENSE,
          variables: { licenseId: id },
        })
        .then((response) => {
          const licenses_tmp = this.state.licenses;
          this.setState({
            licenses: _.filter(licenses_tmp, (l) => l._id !== response.data.deleteLicense._id),
          });
        });
    });
  };

  isLicenseSelected = (id) => _.find(this.state.selectedLicenses, (l) => l === id) !== undefined;

  selectLicense = (id) => {
    let selected_tmp = this.state.selectedLicenses;

    if (this.isLicenseSelected(id)) {
      selected_tmp = _.filter(selected_tmp, (l) => l !== id);
    } else {
      selected_tmp.push(id);
    }

    this.setState({
      selectedLicenses: selected_tmp,
    });
  };

  selectAllLicenses = (evt, checked) => {
    if (!checked) {
      this.setState({
        selectedLicenses: [],
      });
    } else {
      for (let i = 0; i < this.state.licenses.length; i++) {
        if (!this.isLicenseSelected(this.state.licenses[i]._id)) {
          this.selectLicense(this.state.licenses[i]._id);
        }
      }
    }
  };

  deleteSelectedLicenses = () => {
    for (let i = 0; i < this.state.selectedLicenses.length; i++) {
      this.handleDeleteLicense(this.state.selectedLicenses[i]);
    }
  };

  handleTags = (editedLicense) => {
    this.setState({ editedLicense });
    this.handleModalEditTags(true);
  };

  handleModalEditTags = (bool) => {
    this.setState({ edit_tags: bool });
  };

  render() {
    const { t } = this.props;
    const {
      licenses,
      total,
      assigned,
      available,
      total_accounts,
      user_accounts,
      user_licenses,
      user_without_license,
      isAdmin,
      edit_tags,
      editedLicense,
    } = this.state;

    return (
      <div>
        {this.noAccess() ? (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {t("note.complete_establishment")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={40}>
            <Grid item>
              <Typography variant="h4">{t("titles.licenses_summary")}</Typography>
            </Grid>
            <Grid container item spacing={8}>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item>
                    <Chip label={t("license_stats.nb_total") + total} color="primary" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Chip label={t("license_stats.nb_assigned") + assigned} color="primary" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Chip label={t("license_stats.nb_available") + available} color="primary" variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  <Grid item>
                    <Chip label={t("account.nb_total") + total_accounts} color="secondary" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Chip label={t("account.nb_user_accounts") + user_accounts} color="secondary" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Chip label={t("account.nb_user_licenses") + user_licenses} color="secondary" variant="outlined" />
                  </Grid>
                  <Grid item>
                    <Chip label={t("account.nb_user_without_license") + user_without_license} color="secondary" variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isAdmin && (
                <Button
                  disabled={this.state.selectedLicenses.length === 0}
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={() => this.deleteSelectedLicenses()}
                >
                  {t("Supprimer les licences")}
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    {isAdmin && (
                      <TableCell>
                        <Checkbox name="test" onChange={this.selectAllLicenses} value="test" />
                      </TableCell>
                    )}
                    <TableCell>{t("license_id")}</TableCell>
                    <TableCell>{t("name")}</TableCell>
                    <TableCell>{t("type")}</TableCell>
                    <TableCell>{t("expiration")}</TableCell>
                    <TableCell>{t("flags")}</TableCell>
                    {isAdmin && <TableCell>{t("actions")}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenses.map((license) => (
                    <TableRow key={license._id}>
                      {isAdmin && (
                        <TableCell>
                          <Checkbox
                            name={license._id}
                            onChange={() => {
                              this.selectLicense(license._id);
                            }}
                            value={license._id}
                            checked={this.isLicenseSelected(license._id)}
                          />
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {license._id}
                      </TableCell>
                      <TableCell>{license.attributedTo ? this.formatName(license.attributedTo) : "-"}</TableCell>
                      <TableCell>{license.limitedTo ? license.limitedTo : "-"}</TableCell>
                      <TableCell>{formatDateShort(license.expirationDate)}</TableCell>
                      <TableCell>{license.flags.map((flag) => `${flag} `)}</TableCell>
                      {isAdmin && (
                        <TableCell>
                          <IconButton onClick={() => this.handleDeleteLicense(license._id)}>
                            <DeleteIcon />
                          </IconButton>

                          <IconButton onClick={() => this.handleTags(license)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}

        {edit_tags && <TagPicker open={edit_tags} license={editedLicense} onClose={() => this.handleModalEditTags(false)} />}
      </div>
    );
  }
}

export default withTranslation()(LicensesSummary);
