import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow, TableHead, Table, Typography } from "@material-ui/core";
import { getApolloClient } from "utils/apolloClientFactory";
import { GET_GROUP } from "graphql/queries";
import ModalForm from "components/Modal/ModalForm";
import { formatDateShort } from "utils/index";

class LicensePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      licenses: [],
    };
  }

  componentDidMount() {
    const groupID = this.state.user && this.state.user.group && this.state.user.group._id;

    if (groupID) {
      getApolloClient().then((client) => {
        client
          .query({
            query: GET_GROUP,
            variables: { groupID },
          })
          .then((response) => {
            const group = response.data.getGroup;
            this.setState({
              licenses: group.licenses.filter((license) => !license.attributedTo) || [],
            });
          });
      });
    }
  }

  handleClose = () => {
    this.props.onClose();
  };

  noAccess = () => !this.state.user.group;

  pickLicense = (license) => {
    this.props.onClose(license);
  };

  render() {
    const { t, open } = this.props;

    return (
      <div>
        <ModalForm open={open} onClose={this.handleClose} title={t("Sélectionnez la licence à attribuer")}>
          {this.noAccess() ? (
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("note.complete_establishment")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={40}>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("license_id")}</TableCell>
                      <TableCell>{t("type")}</TableCell>
                      <TableCell>{t("expiration")}</TableCell>
                      <TableCell>{t("flags")}</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.licenses.map((license) => (
                      <TableRow key={license._id}>
                        <TableCell component="th" scope="row">
                          {license._id}
                        </TableCell>
                        <TableCell>{license.limitedTo ? license.limitedTo : "-"}</TableCell>
                        <TableCell>{formatDateShort(license.expirationDate)}</TableCell>
                        <TableCell>{license.flags.map((flag) => `${flag} `)}</TableCell>
                        <TableCell>
                          <Button color="primary" fullWidth variant="outlined" onClick={() => this.pickLicense(license)}>
                            {t("Choisir")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
        </ModalForm>
      </div>
    );
  }
}

export default withTranslation()(LicensePicker);
