import * as Sentry from "@sentry/react";

export const logger = {
  debug: console.debug,
  warn: console.warn,
  info: console.log,
  error(err) {
    console.error(err);
    Sentry.captureException(err);
  },
};
