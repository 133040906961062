import { createMuiTheme } from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import { cvVioletColor } from "./variables";

const theme = createMuiTheme({
  palette: {
    // primary: {
    //   50: '#ffe3ef',
    //   100: '#feb9d7',
    //   200: '#fe8abc',
    //   300: '#fd5ba1',
    //   400: '#fc388d',
    //   500: '#fc1579',
    //   600: '#fc1271',
    //   700: '#fb0f66',
    //   800: '#fb0c5c',
    //   900: '#fa0649',
    //   A100: '#ffffff',
    //   A200: '#ffeef2',
    //   A400: '#ffbbca',
    //   A700: '#ffa2b7',
    //   contrastDefaultColor: 'light',
    // },
    primary: {
      main: cvVioletColor,
    },
    secondary: lightBlue,
  },
  spacing: 10,
  error: red,
  status: {
    danger: "orange",
  },
});

export default theme;
