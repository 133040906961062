export const PROFESSIONAL_USER = "professional";
export const HEALTH_FACILITY_USER = "healthFacility";

export const PROFESSIONS = [
  { intl: "professions.speech_therapist", value: "speech_therapist" }, // Orthophoniste
  {
    intl: "professions.occupational_therapist",
    value: "occupational_therapist",
  }, // Ergothérapeute
  { intl: "professions.neuropsychologist", value: "neuropsychologist" }, // Neuropsychologue
  { intl: "professions.psychologist", value: "psychologist" }, // Psychologue
  { intl: "professions.psychomotor_therapist", value: "psychomotor_therapist" }, // Psychomotricien(ne)
  { intl: "professions.health_manager", value: "health_manager" }, // Cadre de santé
  { intl: "professions.doctor_PMR", value: "doctor_PMR" }, // Médecin MPR
  { intl: "professions.doctor_psychiatry", value: "doctor_psychiatry" }, // Médecin psychiatre
  { intl: "professions.doctor_neurology", value: "doctor_neurology" }, // Médecin neurologue
  { intl: "professions.doctor_geriatry", value: "doctor_geriatry" }, // Médecin gériatre
  { intl: "professions.nurse", value: "nurse" }, // Infirmière
  { intl: "professions.researcher", value: "researcher" }, // Chercheur(euse)(e)
  { intl: "professions.student", value: "student" }, // Étudiant(e)
  { intl: "professions.trainer", value: "trainer" }, // Formateur(trice)
  { intl: "professions.other", value: "other" }, // Autre profession
];
