import { cvBluePetrolColor, cvGreenColor, cvBrownColor } from "styles/variables";

const drawerWidth = 260;
const drawerActivityWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
};

const boxShadow = {
  boxShadow: "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "3px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff",
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em",
};

const primaryColor = "#9c27b0";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

// const cvBluePetrolColor = '#96B0BD';
// const cvBrownColor = '#C8B79F';
// const cvGreenColor = '#93D4C1';

const primaryBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(156, 176, 189, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 176, 189, 0.2)",
};
const infoBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
};
const cvBluePetrolColorBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(156, 176, 189, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 176, 189, 0.2)",
};
const cvGreenColorBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(147, 212, 193, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(147, 212, 193, 0.2)",
};
const cvBrownColorBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(200 183 159, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(200 183 159, 0.2)",
};

const dangerBoxShadow = {
  boxShadow: "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
};
const roseBoxShadow = {
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
};

const warningCardHeader = {
  background: "linear-gradient(60deg, #C8B79F, #f5ede2)",
};
const cvBluePetrolColorCardHeader = {
  // background: "linear-gradient(60deg, #66bb6a, #43a047)",
  background: `linear-gradient(60deg, ${cvBluePetrolColor}, #96b0bd54)`,
  ...cvBluePetrolColorBoxShadow,
};
const cvGreenColorCardHeader = {
  // background: "linear-gradient(60deg, #66bb6a, #43a047)",
  background: `linear-gradient(60deg, ${cvGreenColor}, #93d4c154)`,
  ...cvGreenColorBoxShadow,
};
const cvBrownColorCardHeader = {
  // background: "linear-gradient(60deg, #66bb6a, #43a047)",
  background: `linear-gradient(60deg, ${cvBrownColor}, #d4b99354)`,
  ...cvBrownColorBoxShadow,
};
const dangerCardHeader = {
  background: "linear-gradient(60deg, #96B0BD, #decfbb)",
  ...dangerBoxShadow,
};
const infoCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow,
};
const primaryCardHeader = {
  background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  ...primaryBoxShadow,
};
const roseCardHeader = {
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow,
};

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont,
};

const cardHeader = {
  margin: "-20px 15px 0",
  borderRadius: "3px",
  padding: "15px",
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow: "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

const title = {
  color: "#3C4858",
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: "#777",
    fontWeight: "400",
    lineHeight: "1",
  },
};

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",
  },
};

const cardSubtitle = {
  marginTop: "-.375rem",
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
};

export {
  drawerWidth,
  drawerActivityWidth,
  transition,
  container,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  cvBluePetrolColorBoxShadow,
  cvGreenColorBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  cvBluePetrolColorCardHeader,
  cvGreenColorCardHeader,
  cvBrownColorCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,
};
