import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import appLayoutStyle from "assets/jss/layouts/appLayoutStyle.jsx";
import { Route } from "react-router-dom";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import NavigationSideBar from "components/NavigationSideBar/NavigationSideBar";
import HeaderLinks from "components/HeaderLinks/HeaderLinks";

const AuthenticatedLayoutStructure = ({ user, children }) => (
  <div style={{ display: "flex", overflowX: "hidden" }}>
    <div style={{ width: "175px", height: "100vh" }}>
      <NavigationSideBar />
    </div>
    <div style={{ flexDirection: "column", width: "100%", margin: "10px" }}>
      {user && (
        <div
          style={{
            height: "100px",
            display: "flex",
            "align-items": "center",
            "justify-content": "end",
            padding: "20px",
          }}
        >
          <HeaderLinks />
        </div>
      )}
      <div style={{ maxHeight: "100vh", padding: "30px" }}>{children}</div>
    </div>
  </div>
);

const AuthenticatedLayout = ({ component: Component, ...rest }) => {
  const currentUser = window.covirtua_current_user;
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthenticatedLayoutStructure user={currentUser}>
          <Component {...props} />
        </AuthenticatedLayoutStructure>
      )}
    />
  );
};

export default withStyles(appLayoutStyle)(AuthenticatedLayout);
