const trad = {
  fields: {
    email: "Adresse mail",
    password: "Mot de passe",
    confirm_password: "Confirmation mot de passe",
    first_name: "Prénom",
    last_name: "Nom",
    phone: "Téléphone",
    user_type: "Je suis...",
    health_facility_user: "Établissement de santé",
    professional_user: "Professionnel libéral",
    accept_cgu_cgv: "J'accepte les conditions générales de vente et d'utilisation",
    establishment: {
      name: "Nom établissement",
      phone: "Standard téléphonique",
      address: "Adresse établissement",
    },
    professional: {
      profession: "Profession",
      rpps: "Numéro RPPS / Adeli",
      address: "Adresse professionnelle",
    },
    service: "Service",
    address: "Adresse",
    number: "Numéro",
    street: "Rue",
    city: "Ville",
    zip: "Code postal",
    birth_date: "Date de naissance",
    code: "Code",
    patient_code: "Je renseigne le code qui m’a été transmis",
  },
  roles: {
    patient: "Patient",
    therapist: "Thérapeute",
    admin: "Administrateur",
    supervisor: "Superviseur",
    manager: "Gestionnaire",
    user: "Utilisateur",
  },
  forgot_password: "Mot de passe oublié ?",
  forgot_password_label: "Veuillez entrer votre adresse mail associé à votre compte s’il vous plait.",
  reset: "Réinitialiser",
  forgot_password_confirmation:
    "Un lien a été envoyé sur votre adresse mail afin de réinitialiser votre mot de passe. Attention, ce lien est valable 24h00 seulement.",
  create_account: "Créer un compte",
  go_back_home: "Retour à l'accueil du site",
  send: "Envoyer",
  resend: "Renvoyer",
  send_again_email: "Renvoyer le mail",
  cgv_description: "Description des conditions générales de ventes",
  no_code: "Je n’ai pas accès à mon code",
  confirm: "Confirmer",
  validate: "Valider",
  delete: "Supprimer",
  cancel: "Annuler",
  birth_date_label: "Bonjour, pouvez-vous me confirmer que vous êtes bien {{user}} en indiquant votre date de naissance s’il vous plait ?",
  create_account_confirmation:
    "Un mail a été envoyé à l’adresse {{email}}. Veuillez suivre le lien afin de confirmer la création de votre compte.",
  create_account_password: "Merci d’avoir confirmé votre identité. Veuillez désormais renseigner le mot de passe de votre compte.",
  login: "Se connecter",
  logout: "Se déconnecter",
  activation_email_activated: "Votre compte est déjà activé, vous pouvez vous connecter.",
  activation_email_confirmation: "Le lien d'activation a été envoyé sur votre adresse mail.",
  save: "Enregistrer",
  errors: {
    required: "Ce champ est obligatoire",
    email: "Email non valide",
    password_mismatch: "Le mot de passe ne correspond pas",
    phone_invalid: "Numéro de téléphone invalide",
  },
  navigation: {
    profile: "Profil",
    licenses: "Licences",
    users: "Utilisateurs",
    groups: "Gestion groupes",
    establishment: "Établissement",
  },
  titles: {
    sign_in: "Connexion",
    sign_up: "Inscription",
    forgot_password: "Mot de passe oublié",
    account_activation: "Activation du compte",
    activation_email: "Envoie du mail d'activation",
    licenses_summary: "Récapitulatif des licences",
    establishment: "Établissement",
    profile: "Profil",
    users_summary: "Récapitulatif des utilisateurs",
    groups_summary: "Récapitulatif des groupes",
    add_user: "Ajouter un compte",
    edit_user: "Éditer un compte",
    edit_group: "Éditer un groupe",
    delete_user: "Supprimer un compte",
  },
  role: "Rôle(s)",
  name: "Nom",
  type: "Type",
  status: "Statut",
  flags: "Options",
  actions: "Actions",
  expiration: "Expiration",
  license: "Licence",
  nb_licenses: "Nombre de licences",
  nb_months: "Nombre de mois",
  license_stats: {
    nb_total: "Nombre total de licences : ",
    nb_assigned: "Nombre de licences attribués : ",
    nb_available: "Nombre de licences disponibles : ",
  },
  group_stats: {
    nb_total: "Nombre total de groupes : ",
  },
  add_licenses: "Ajouter des licences",
  modify_licenses_flags: "Modifier le type de licenses",
  delete_licenses: "Supprimer des licences",
  assign_license: "Attribuer une licence",
  delete_license: "Retirer la licence",
  license_id: "ID licence",
  license_type: "Type de licence",
  license_expiration: "Expiration licence",
  last_connection: "Dernière connexion",
  account: {
    nb_total: "Nombre total de comptes : ",
    nb_user_accounts: "Nombre de comptes utilisateurs : ",
    nb_user_licenses: "Nombre d'utilisateurs avec licence : ",
    nb_user_without_license: "Nombre d'utilisateurs sans licence : ",
  },
  professions: {
    speech_therapist: "Orthophoniste",
    occupational_therapist: "Ergothérapeute",
    neuropsychologist: "Neuropsychologue",
    psychologist: "Psychologue",
    psychomotor_therapist: "Psychomotricien.ne",
    health_manager: "Cadre de santé",
    doctor_PMR: "Médecin MPR",
    doctor_psychiatry: "Médecin psychiatre",
    doctor_neurology: "Médecin neurologue",
    doctor_geriatry: "Médecin neurologue",
    nurse: "Infirmier.ère",
    researcher: "Chercheur.euse",
    student: "Étudiant",
    trainer: "Formateur.trice",
    other: "Autre",
  },
  dialog: {
    delete_user_title: "Attention vous êtes sur le point de supprimer l'utilisateur {{email}}",
    delete_user_content: "Etes-vous sûr de vouloir supprimer son compte ?",
    add_license_title: "Attention, vous êtes sur le point d’attribuer une licence à {{email}}",
    add_license_content: "Etes-vous sûr de vouloir attribuer une licence ?",
    delete_license_title: "Attention, vous êtes sur le point de retirer une licence à {{email}}",
    delete_license_content: "Etes-vous sûr de vouloir retirer cette licence ?",
    add_licenses_title: "Attention, vous êtes sur le point d’ajouter des licences à {{group}}",
    add_licenses_content: "Etes-vous sûr de vouloir d'ajouter {{nb}} licences ?",
    delete_licenses_title: "Attention, vous êtes sur le point de supprimer des licences à {{group}}",
    delete_licenses_content: "Etes-vous sûr de vouloir supprimer {{nb}} licences ?",
  },
  snackbar: {
    error: "Une erreur est survenue",
    success: "Opération avec succès",
    update_profile: "Les infos du profil ont été sauvegardées",
    update_account: "Les infos du compte {{email}} ont été sauvegardées",
    update_establishment: "Les infos de l'établissement ont été sauvegardées",
    create_account:
      "Le compte a bien été créé. Un mail a été envoyé à l’adresse {{email}}.Pour lui affecter une licence, rendez-vous dans les détails de son compte !",
    account_already_assigned:
      "Le compte {{email}} n'a pas pu être créé: l'utilisateur est déjà affecté au groupe {{group}}",
    delete_account: "Le compte de {{email}} a bien été supprimé.",
    add_license: "Nouvelle licence attribuée à {{email}} !",
    delete_license: "La licence de {{email}} a été désaffectée.",
  },
  note: {
    complete_profile: "Vous devez d'abord compléter votre profil",
    complete_establishment: "Vous devez d'abord compléter les infos de l'établissement",
  },
  account_active: "Actif",
  account_non_active: "Non actif",
};
trad.Main_Disconnect = `Déconnexion`;
trad.Main_App = `Accès à l'application`;
export { trad };
