const trad = {
  fields: {
    email: "Email address",
    password: "Password",
    confirm_password: "Password confirmation",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    user_type: "I am...",
    health_facility_user: "Health facility",
    professional_user: "Liberal professional",
    accept_cgu_cgv: "I accept the general conditions of sale and use",
    establishment: {
      name: "Establishment name",
      phone: "Telephone switchboard",
      address: "Establishment address",
    },
    professional: {
      profession: "Profession",
      rpps: "RPPS / Adeli number",
      address: "Business address",
    },
    service: "Service",
    address: "Address",
    number: "Number",
    street: "Street",
    city: "City",
    zip: "Zip code",
    birth_date: "Birthdate",
    code: "Code",
    patient_code: "I enter the code that was sent to me",
  },
  roles: {
    patient: "Patient",
    therapist: "Therapist",
    admin: "Administrator",
    supervisor: "Supervisor",
    manager: "Manager",
    user: "User",
  },
  forgot_password: "Forgot your password?",
  forgot_password_label: "Please enter your email address associated with your account.",
  reset: "Reset",
  forgot_password_confirmation:
    "A link has been sent to your email address to reset your password. Please note that this link is valid for 24 hours only.",
  create_account: "Create account",
  go_back_home: "Back to the home page",
  send: "Send",
  resend: "Resend",
  send_again_email: "Resend email",
  cgv_description: "Description of the general sales conditions",
  no_code: "I don't have access to my code",
  confirm: "Confirm",
  validate: "Validate",
  delete: "Delete",
  cancel: "Cancel",
  birth_date_label: "Hello, can you confirm that you are {{user}} by indicating your date of birth please?",
  create_account_confirmation: "An email has been sent to {{email}}. Please follow the link to confirm the creation of your account.",
  create_account_password: "Thank you for confirming your identity. Please enter your account password.",
  login: "Login",
  logout: "Logout",
  activation_email_activated: "Your account is already activated, you can login.",
  activation_email_confirmation: "The activation link has been sent to your email address",
  save: "Save",
  errors: {
    required: "This field is required",
    email: "Invalid email",
    password_mismatch: "The password does not match",
    phone_invalid: "Invalid phone number",
  },
  navigation: {
    profile: "Profile",
    licenses: "Licenses",
    users: "Users",
    groups: "Management groups",
    establishment: "Establishment",
  },
  titles: {
    sign_in: "Sign in",
    sign_up: "Sign up",
    forgot_password: "Forgot password",
    account_activation: "Account activation",
    activation_email: "Send email activation",
    licenses_summary: "Licenses summary",
    establishment: "Establishment",
    profile: "Profile",
    users_summary: "Users summary",
    groups_summary: "Groups summary",
    add_user: "Add account",
    edit_user: "Edit account",
    edit_group: "Edit Group",
    delete_user: "Delete account",
  },
  role: "Role",
  name: "Name",
  type: "Type",
  status: "Status",
  expiration: "Expiration",
  license: "License",
  nb_licenses: "Licenses number",
  nb_months: "Months number",
  license_stats: {
    nb_total: "Total number of licenses: ",
    nb_assigned: "Number of licenses assigned: ",
    nb_available: "Number of available licenses: ",
  },
  group_stats: {
    nb_total: "Total number of groups: ",
  },
  add_licenses: "Add licenses",
  delete_licenses: "Delete licenses",
  assign_license: "Assign a license",
  delete_license: "Remove license",
  license_id: "License ID",
  license_type: "License type",
  license_expiration: "License expiration",
  last_connection: "Last connection",
  account: {
    nb_total: "Total number of accounts: ",
    nb_user_accounts: "Number of user accounts: ",
    nb_user_licenses: "Number of users with license: ",
    nb_user_without_license: "Number of users without license: ",
  },
  professions: {
    speech_therapist: "Speech therapist",
    occupational_therapist: "Occupational therapist",
    psychomotrician: "Psychomotrician",
    doctor: "Doctor",
    student: "Student",
    researcher: "Researcher",
    other: "Other",
  },
  dialog: {
    delete_user_title: "Attention you are about to delete the user {{email}}",
    delete_user_content: "Are you sure you want to delete his account?",
    add_license_title: "Attention, you are about to license {{email}}",
    add_license_content: "Are you sure you want to assign a license?",
    delete_license_title: "Attention, you are about to remove {{email}} license",
    delete_license_content: "Are you sure you want to remove this license?",
    add_licenses_title: "Attention, you are about to add licenses to {{group}}",
    add_licenses_content: "Are you sure you want to add {{nb}} licenses?",
    delete_licenses_title: "Attention, you are about to delete {{group}} licenses",
    delete_licenses_content: "Are you sure you want to delete {{nb}} licenses?",
  },
  snackbar: {
    error: "An error has occurred",
    success: "Successful operation",
    update_profile: "Your information has been saved",
    update_account: "The {{email}} account information has been saved",
    update_establishment: "The establishment's information has been saved",
    create_account: "The account has been created. An email has been sent to {{email}}. To assign a license, go to the account details!",
    delete_account: "The account of {{email}} has been deleted.",
    add_license: "New license assigned to {{email}} !",
    delete_license: "The {{email}} license has been decommissioned.",
  },
  note: {
    complete_profile: "You must complete your profile first",
    complete_establishment: "You must complete the establishment form",
  },
  account_active: "Active",
  account_non_active: "Non active",
};

export { trad };
